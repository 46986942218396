import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavbarLeftService {

  open = true

  constructor() { }
}
