import { Component, inject } from '@angular/core';
import { faAdd, faEllipsisVertical, faUser } from '@fortawesome/pro-regular-svg-icons';
import { ProjectsService } from 'src/app/services/projects.service';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { NgFor, AsyncPipe } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faSidebar } from '@fortawesome/pro-thin-svg-icons';
import { NavbarLeftService } from 'src/app/service/navbar-left.service';

@Component({
  selector: 'app-navigation-left',
  templateUrl: './navigation-left.component.html',
  styleUrls: ['./navigation-left.component.scss'],
  standalone: true,
  imports: [FaIconComponent, NgFor, RouterLink, RouterLinkActive, AsyncPipe]
})
export class NavigationLeftComponent {

  iconSidebar = faSidebar
  iconAdd = faAdd
  iconUser = faUser
  iconDots = faEllipsisVertical

  navbar = inject(NavbarLeftService)

  constructor(
    public projects: ProjectsService,
    private router: Router
  ) { }

  async showModal() {
    this.router.navigate([{ outlets: { modal: ['center-panel', 'add-list'] } }])
  }

  async openUserProfile() {
    this.router.navigate([{ outlets: { modal: ['side-panel', 'user-profile'] } }])
  }

  async showListSettings() {
    this.router.navigate([{ outlets: { modal: ['side-panel', 'list-settings'] } }])
  }
}
