import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output, ViewChild, ViewChildren } from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { WorkstreamPropertyService } from 'src/app/services/workstream-property.service';
import { ownerOptions, statusOptions } from './optionsDropdown';
import { Property } from 'annex-tracker-backend';
import { HWorkstream } from 'annex-tracker-backend/lib/helpers/sortWorkstreamHierarchical'
import { ColumnsService } from 'src/app/services/columns.service';
import { WorkstreamInlineEditDirective } from 'src/app/common/workstream-inline-edit.directive';
import { RowContextComponent } from './row-context/row-context.component';
import { BadgeDropdownComponent } from '../../../../../common/badge-dropdown/badge-dropdown.component';
import { WorkstreamInlineEditDirective as WorkstreamInlineEditDirective_1 } from '../../../../../common/workstream-inline-edit.directive';
import { CursorChangeFieldDirective } from '../../../../../common/cursor-change-field.directive';
import { NgIf, NgFor, NgSwitch, NgSwitchCase, AsyncPipe } from '@angular/common';

@Component({
    selector: '[app-workstreams-table-row]',
    templateUrl: './workstreams-table-row.component.html',
    styleUrls: ['./workstreams-table-row.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        CursorChangeFieldDirective,
        WorkstreamInlineEditDirective_1,
        NgFor,
        NgSwitch,
        NgSwitchCase,
        BadgeDropdownComponent,
        RowContextComponent,
        AsyncPipe,
    ],
})
export class WorkstreamsTableRowComponent {

  @Input()
  editable = false

  @Input()
  workstream!: HWorkstream

  @Input()
  index!: number

  @Input()
  active = false

  @Output()
  focusedColumn = new EventEmitter<string>()

  @ViewChildren(WorkstreamInlineEditDirective)
  inlineEditFields!: WorkstreamInlineEditDirective[]

  ownerOptions = ownerOptions
  statusOptions = statusOptions

  @HostBinding("class.line-through")
  lineThrough = false

  properties?: Property[]
  comment?: Comment

  @HostListener("keydown.shift.backspace", ["$event"])
  @HostListener("keydown.ctrl.backspace", ["$event"])
  onKeyPressed(e: KeyboardEvent) {

    e.preventDefault()
    e.stopPropagation()

    this.workstreamProperty.setTo("deleted", (this.workstream?.properties?.['deleted'] || "false") == 'false' ? 'true' : 'false', this.workstream)
  }

  constructor(
    public workstreamProperty: WorkstreamPropertyService,
    private backend: BackendService,
    public elementRef: ElementRef<HTMLTableRowElement>,
    public columnService: ColumnsService
  ) { }

  focus(elementName: string, side: 'left' | 'right') {
    
    let element: HTMLElement | undefined = undefined

    let editField = this.inlineEditFields.find(field => field.propertyName == elementName)

    element = editField?.elementRef.nativeElement


    if (element == null) return

    var setpos = document.createRange();
    var set = window.getSelection();

    let targetPosition = side == 'left' ? 0 : (element?.textContent?.length || 0)

    // Select the actual text node inside <td>
    if (element.childNodes.length > 0) {

       setpos.setStart(element.childNodes[0], targetPosition);
       setpos.collapse(true);
  
       set?.removeAllRanges();
       set?.addRange(setpos);
    }

    if (element.innerText.length == 0) {

      element.innerText = ' ';
      setTimeout(() => {
        element?.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        })
        element!.innerText = ''
      }, 1)
    }

    element?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
    
    element?.focus();

  }

}
