import { Component, inject } from '@angular/core';
import { ProjectsService } from '../services/projects.service';
import { RouterOutlet } from '@angular/router';
import { NavigationLeftComponent } from './navigation-left/navigation-left.component';
import { NavbarLeftService } from '../service/navbar-left.service';

@Component({
    selector: 'app-multi-column',
    templateUrl: './multi-column.component.html',
    styleUrls: ['./multi-column.component.scss'],
    standalone: true,
    imports: [NavigationLeftComponent, RouterOutlet]
})
export class MultiColumnComponent {

  projects = inject(ProjectsService)
  navbar = inject(NavbarLeftService)

}
