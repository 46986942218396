import { Component, inject } from '@angular/core';
import { HeaderButtonsService } from 'src/app/header-buttons.service';
import { EmailService } from 'src/app/services/email.service';
import { NgIf, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NavbarLeftService } from 'src/app/service/navbar-left.service';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { ProjectsService } from 'src/app/services/projects.service';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [RouterLink, RouterLinkActive, NgIf, NgTemplateOutlet, AsyncPipe, FaIconComponent]
})
export class HeaderComponent {

  iconLeft = faArrowLeft

  navbar = inject(NavbarLeftService)
  projectService = inject(ProjectsService)

  constructor(
    public headerButtons: HeaderButtonsService,
    public emailsService: EmailService,
  ) { }

}
